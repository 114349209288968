<template>
  <a class="accessibility" href="#main">Skip to Content</a>
  <noscript>
    <div>Javascript must be enabled for the correct page display</div>
  </noscript>
  <div class="wrapper">
    <header class="header" id="header">
      <div class="container container-sm">
        <div class="logo-login">
          <a href="#"><img src="/images/logo-light.svg" width="294" height="145" alt="Audioscribe" /></a>
        </div>
      </div>
    </header>
    <div class="page-holder">
      <main class="main" id="main">
        <section class="login-section">
          <span class="login_decor"><img src="/images/logo-decor.svg" width="1162" height="1162" alt="Image Description" /></span>
          <div class="container container-sm">
            <h1>Payment Successful!</h1>
            <div class="text-holder text-center">
              <p>Navigating back to the home page in a few seconds. If the page doesn't automatically refresh please click <router-link to="/">here</router-link></p>
            </div>
          </div>
        </section>
      </main>
    </div>
    <footer class="footer" id="footer">
      <!-- footer content -->
    </footer>
  </div>
</template>


<script>
export default {
    name: "PaymentSuccessView",
    mounted() {
        setTimeout(() => {
            this.$router.push('/');
        }, 2000); // Redirect after 3 seconds (adjust the delay as needed)
    },
    created() {
        // Add the 'login-page' class to the body and remove the 'nav-active' class
        document.body.classList.add('login-page');
        document.body.classList.remove('nav-active');
    },
    beforeDestroy() {
        // Reset the class back to 'nav-active' when navigating away from this page
        document.body.classList.add('nav-active');
        document.body.classList.remove('login-page');
    },
};
</script>